<template>
  <b-container>
    <create-form
      :merchant="merchant"
      @click-button="createMerchant"
    />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import MerchantService from '@/services/osiris/MerchantService';

import CreateForm from './components/Form.vue';

import { Crypto } from '@/libs/crypto';

export default {
    components: {
        BContainer,
        CreateForm,
    },

    data() {
        return ({
            merchant: {
                user_id: Crypto.decrypt(sessionStorage.getItem('id')),
                address: {
                    country: 'BR',
                },
            },
        });
    },

    methods: {
        async createMerchant() {
            const isConfirmed = await this.confirmAnAction('Você deseja realmente criar esse estabelecimento?');
            if (!isConfirmed) return;

            this.isLoading(true);
            const { status } = await MerchantService.create({
                ...this.merchant,
                tax_id: this.merchant.tax_id.replace(/[^\d]+/g, ''),
                address: this.merchant.address.postalCode ? this.merchant.address : undefined,
            });
            this.isLoading(false);

            if (status === 200 || status === 201) {
                this.modalSuccess('Estabelecimento criado com sucesso');
                this.$router.push('/');
            }    if (status === 409) {
                this.modalError('Estabelecimento já existente em nossa base');
                this.$router.push('/');
            }
            else {
                this.modalError('Não foi possível criar esse estabelecimento');
            }
        },
    },
};
</script>
